@import 'assets/_constants';
@import 'assets/_mixins';

.f-settings-tabs {
  @include flex-space;
  justify-content: flex-start;
  margin-bottom: 1px;;

  .f-settings-tabs-item {
    padding: 8px 16px;
    background-color: #e5e9e5;
    border: 1px #b0ab74 solid;
    &.active {
      background-color: #FFF;
      border-bottom: 1px transparent solid;
    }
  }
}



